import "./document_modal.css";


$('#fileModal').on('show.bs.modal', function(e) {

    //get data-id attribute of the clicked element
    var chatId = $(e.relatedTarget).data('chat-id');
    if (chatId == window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) {
      $(e.currentTarget).find('input[name="need[chat_id]"]').val(chatId);
    } else {
      $(e.currentTarget).find('input[name="need[chat_id]"]').val('');
    }
});
