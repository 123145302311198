import NestedFormController from "./nested_form_controller";

export default class extends NestedFormController {
  addAssociation(event) {
    event.preventDefault();
    const content = this.formatTemplate(event);
    this.insert_wrapperTarget.insertAdjacentHTML("beforeend", content);
  }

  removeAssociation(event) {
    super.removeAssociation(event);
  }

  formatTemplate(event) {
    const domEl =
      event.target.parentElement.tagName === "A"
        ? event.target.parentElement
        : event.target;
    const childIdxRegex = new RegExp(
      this.templateTarget.dataset.childIndex,
      "g"
    );
    let content = this.templateTarget.innerHTML;
    const selectedAttrs = JSON.parse(domEl.dataset.attributes);
    Object.keys(selectedAttrs).forEach(k => {
      content = content.replace(
        new RegExp(`default_${k}`, "ig"),
        selectedAttrs[k]
      );
      return null;
    });
    return content.replace(childIdxRegex, new Date().valueOf());
  }
}
