import Choices from "choices.js";

import "choices.js/src/styles/choices.scss";
import "./form_step.css";

// Set client choices-js for admin
const userClientSelect = document.getElementById("answers_membership_id");
if (userClientSelect) {
  const choices = new Choices(userClientSelect);
}
