import React, { Component } from 'react';
import "./hit.css";

class Hit extends Component {

  render() {
    return (
      <a className="hit" href={this.props.hit.get_page_path}>
        {this.props.hit.name}
      </a>
    );
  }
}


export default Hit;
