import { Loader } from "@googlemaps/js-api-loader";

const initAutocomplete = () => {
  const loader = new Loader({
    apiKey: "AIzaSyA_nc369msfk0N6lihchvH3eOHtTNaS-Dw",
    version: "weekly",
    libraries: ["places"]
  });

  const center = { lat: 50.064192, lng: -130.605469 };
  // Create a bounding box with sides ~10km away from the center point
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1
  };
  const options = {
    bounds: defaultBounds,
    componentRestrictions: { country: "fr" },
    fields: ["address_components"],
    types: ["address"]
  };

  const addressInput = document.querySelector(".address-input");

  loader.load().then(google => {
    const autocomplete = new google.maps.places.Autocomplete(
      addressInput,
      options
    );

    autocomplete.addListener("place_changed", () =>
      fillInAddress(autocomplete)
    );
  });
};

function fillInAddress(autocomplete) {
  const address1Field = document.querySelector(".address-input");
  const addressStreetField = document.querySelector(
    ".address-input-street-address"
  );

  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();
  let address1 = "";

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name} ${address1}`;
        const addressStreetField = document.querySelector(
          ".address-input-street-address"
        );
        if (addressStreetField) {
          addressStreetField.value = component.long_name;
        }
        break;
      }

      case "route": {
        address1 += component.short_name;
        break;
      }

      case "postal_code": {
        const postcode = component.long_name;
        const postCodeEl = document.querySelector(".address-input-postcode");
        if (postCodeEl) {
          postCodeEl.value = postcode;
        } else {
          address1 += `, ${postCode}`;
        }
        break;
      }
      case "locality":
        const cityEl = document.querySelector(".address-input-city");
        if (cityEl) {
          cityEl.value = component.long_name;
        } else {
          address1 += `, ${component.long_name}`;
          const countryEl = place.address_components(c =>
            c.types.includes("country")
          );
          if (countryEl) {
            address1 += `, ${countryEl.long_name}`;
          }
        }
        break;
    }
  }

  addressStreetField.value = address1;
  // After filling the form with address components from the Autocomplete
  // prediction, set cursor focus on the second address line to encourage
  // entry of subpremise information such as apartment, unit, or floor number.
  address1Field.focus();
}

export { initAutocomplete };
