import React, {Component, Fragment} from 'react';

import "./invoices_list.css";
import classNames from "classnames";

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

import { rankItem } from '@tanstack/match-sorter-utils'
import {compact} from "lodash/array";

const Debug = (props = {}) => {
    return <>
        <p>In comp</p>
        <pre>{JSON.stringify(props, null, 2)}</pre>
    </>
}

// A debounced input react component
function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value, { threshold: 3 })

    // Store the itemRank info
    addMeta({ itemRank, })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const tradState = {"draft": "Brouillon", "due": "A régler", "payinprogress": "Paiement partiel ", "paid": "Payée", "late": "Retard", "cancelled": "Annulée"}

const FirmCell = ({ name, logo }) => {
    return (
        <div className="firm-container">
            <div className="logo-container">
                <img src={logo} />
            </div>
            {name}
        </div>
    )
}

const columnHelper = createColumnHelper()
const columnsDefs = [
    {
        header: "Num°",
        accessor: "need_id",
        cell: info => <div className="text">{info.getValue()}</div>
    },
    {
        header: "Client",
        accessor: "client_name",
        cell: info => <div className="text">{info.getValue()}</div>
    },
    {
        header: "Prestataire",
        accessor: "firm_name",
        cell: info => <FirmCell name={info.getValue()} logo={info.row.original.firm_logo} />
    },
    {
        header: "Type",
        accessor: "subscription",
        cell: info => <div className="text">{info.getValue() ? "Abonnement" : "Ponctuel" }</div>
    },
    {
        header: "Service",
        accessor: "service_name",
        cell: info => <div className="text">{info.getValue()}</div>
    },
    {
        header: "Num° facture",
        accessor: "ref",
        cell: info => <div className="text">{info.getValue() ?? "N/A" }</div>
    },
    {
        header: "Statut",
        accessor: 'state',
        cell: info => {
            const value = info.getValue()
            const trad = tradState[value] ?? value
            return <div className={value}>{trad}</div>
        }
    },
    {
        header: "Montant TTC",
        accessor: "amount_incl",
        cell: info => <div className="text">{`${info.getValue()?.toLocaleString()}€`}</div>
    },
    {
        header: "",
        accessor: "url",
        cell: info => <div className="text"><a className="pdf-link" href={info.getValue()} target="_blank"></a></div>
    },
    {
        id: 'arrow',
        forceShow: true,
        header: () => null,
        cell: ({row}) => {
            return row.getCanExpand() ? (
                <div
                    {...{
                        onClick: row.getToggleExpandedHandler(),
                    }}
                >
                    <span></span>
                </div>
            ) : (
                '🔵'
            )
        },
    }
]

const renderSubComponent = ({ row }) => {
    const { date, amount_payed, amount_incl, user } = row.original
    return (
        <div className="invoice-detail">
            <div className="date">
                <div className="entete">Date de la facture</div>
                {date}
            </div>
            <div className="payed">
                <div className="entete">Montant payé</div>
                {amount_payed == 0.0 ? '-' : `${amount_payed}€`}
            </div>
            <div className="left">
                <div className="entete">Restant à payer</div>
                {amount_incl - amount_payed}€
            </div>
            <div className="user">
                <div className="entete">Devis validé par</div>
                {user}
            </div>
        </div>
    )
}


function InvoiceList(props = {}) {
    const { invoices, showColumns } = props
    const [data, setData] = React.useState(() => [...invoices])
    const rerender = React.useReducer(() => ({}), {})[1]

    let columns = columnsDefs.map(def => {
        const show = showColumns.find(d => d === def.accessor)
        return (show || def.forceShow) && columnHelper.accessor(def.accessor, def)
    })
    columns = compact(columns);

    const [globalFilter, setGlobalFilter] = React.useState('')
    const [sorting, setSorting] = React.useState([])

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
            sorting,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        autoResetExpanded: false,
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowCanExpand: () => true,
        renderSubComponent: renderSubComponent
    })

    return (
        <div className='datatable block shadow'>
            <div className='datatable__menu'>
                <div className="datatable__menu__search">
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        className="datatable__menu__search__input"
                        placeholder="Rechercher..."
                    />
                    <div className="datatable__menu__search__icon">
                        <div className="img"></div>
                    </div>
                </div>
            </div>
            <div className="datatable__table-wrapper">
                <table>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr className={headerGroup.id} key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >{
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: <span className="caret"></span>,
                                                desc: <span className="caret caret--reversed"></span>,
                                            }[header.column.getIsSorted()] ?? null}
                                    </div>}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <Fragment key={row.id}>
                            <tr className={classNames({'selected': row.getIsExpanded()})}>
                                {row.getVisibleCells().map(cell => (
                                    <td className={classNames(cell.column.id, cell.column.className)} key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                            {row.getIsExpanded() && (
                                <tr className="invoice-detail-row">
                                    <td colSpan={row.getVisibleCells().length}>
                                        {renderSubComponent({row})}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    ))}
                    </tbody>
                </table>
            </div>
            <div/>
            <div className="datatable__pagination-wrapper">
                <div className="pagination">
                    <button
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </button>
                    {" "}
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>
                    {" "}
                    <span>
                    Page{" "}
                        <input className='go-to-page' value={table.getState().pagination.pageIndex + 1}
                               onChange={
                                   e => {
                                       const page = e.target.value ? Number(e.target.value) - 1 : 0
                                       table.setPageIndex(page)
                                   }}
                        />{" "}
                        sur {table.getPageCount()}
                  </span>{" "}
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    {" "}
                    <button
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </button>
                    {" "}
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}
export default InvoiceList

