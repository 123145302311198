import NotifsProvider from "../../containers/notifs_provider/notifs_provider";
import SearchBar from "../../components/search_bar/search_bar";
import "./navbar.css";
import "./static/logo.png";

const reactRootDomSelectors = {
  "#search_navbar__js-search-riing": { component: SearchBar }
};

const userTokenDomEl = document.getElementById("js-navbar-riing__user-token");
if (userTokenDomEl) {
  const userToken = userTokenDomEl.dataset.userToken;
  reactRootDomSelectors["#js-navbar-riing__notif-root"] = {
    component: NotifsProvider,
    props: { userToken }
  };
}
export { reactRootDomSelectors };
