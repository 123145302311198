import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchNotifs, receivedNotifs, subscribeToChannel, unsubscribeFromChannel } from '../../actions/index';

import NotifCounter from '../../containers/notif_counter/notif_counter';

class NotifsProvider extends Component {
  componentWillMount() {
    this.props.fetchNotifs();
    this.props.subscribeToChannel("NotificationsChannel", parseInt(this.props.userToken), this.props.receivedNotifs)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.props.notifs.map(n => n.id).sort()) !== JSON.stringify(nextProps.notifs.map(n => n.id).sort());
  }

  componentWillUnmount() {
    this.props.unsubscribeFromChannel("NotificationsChannel", parseInt(this.props.userToken), null)
  }

  render() {
    return <NotifCounter count={this.props.notifs.length}/>
  }
}

function mapStateToProps(state) {
  return {
    notifs: state.notifs.filter(n => n.read_at == null)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchNotifs, receivedNotifs, subscribeToChannel, unsubscribeFromChannel }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifsProvider);
