import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add_item", "insert_wrapper", "template"];

  addAssociation(event) {
    event.preventDefault();
    // const newIndex = new Date().valueOf();
    const childIdxRegex = new RegExp(
      this.templateTarget.dataset.childIndex,
      "g"
    );
    const content = this.templateTarget.innerHTML.replace(
      childIdxRegex,
      new Date().valueOf()
    );
    this.insert_wrapperTarget.insertAdjacentHTML("beforeend", content);
  }
  /* eslint-disable */
  removeAssociation(event) {
    event.preventDefault();
    const item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }
  /* eslint-enable */
}
