import "./onboarding_form_field_choices.css";

import { Application, Controller } from "stimulus";
// import DragAndDropController from "../../controllers/drag_and_drop_controller"

const app = Application.start();
app.register(
  "other-choice-multiple",
  class extends Controller {
    static targets = ["other_option", "other_content"];

    selectOption(event) {
      if (event.target === this.other_optionTarget) {
        if (this.other_contentTarget.classList.contains("active")) {
          this.other_contentTarget.classList.remove("active");
          this.other_contentTarget.value = null;
        } else {
          this.other_contentTarget.classList.add("active");
          this.other_contentTarget.value = null;
        }
      }
    }
  }
);
