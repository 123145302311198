import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "reset_count", "input"];

  setPositions(event) {
    event.preventDefault();
    ["step", "question", "choice"].forEach(identifier => {
      // TO DO: handle count break ... if(identifier === 'choice') { debugger }
      const siblingInputs = document.querySelectorAll(
        `input[data-sibling-identifier="${identifier}"]`
      );
      siblingInputs.forEach((input, index) => {
        // if (input.dataset.reset_count ) { counter = 0 }
        input.value = index + 1;
      });
    });
    this.formTarget.submit();
  }
}
