// TO DO: refacto const selector & siblings
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["drag_key"];

  dragstart(event) {
    console.log("dragstart");
    const currentPosition = this.getDomPosition();
    event.dataTransfer.setData("application/drag-key", currentPosition);
    event.dataTransfer.effectAllowed = "move";
  }
  dragover(event) {
    console.log("dragover");
    event.preventDefault();
    return true;
  }
  dragenter(event) {
    console.log("dragenter");
    event.preventDefault();
  }
  drop(event) {
    console.log("drop");
    // NB: careful with the use of Targets since we have different isntance of controller (not shared)
    const selector = `[data-sibling-identifier="${this.drag_keyTarget.dataset.siblingIdentifier}"]`;
    const siblings = document.querySelectorAll(selector);
    const draggedItemPosition = event.dataTransfer.getData(
      "application/drag-key"
    );
    const dropTarget = event.target.dataset.siblingIdentifier
      ? event.target
      : event.target.closest(selector);
    if (!dropTarget.dataset.siblingIdentifier) {
      debugger;
    }

    const draggedItem = siblings[draggedItemPosition]; // if we have differeent instance of controller cannot use this.drag_keyTarget;
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);
    if (positionComparison & 4) {
      dropTarget.insertAdjacentElement("beforebegin", draggedItem);
    } else if (positionComparison & 2) {
      dropTarget.insertAdjacentElement("afterend", draggedItem);
    }
    event.preventDefault();
  }
  dragend(event) {
    console.log("dragend");
    // Delegate new position to positionning controller
    // // Set new position
    // const selector = `[data-sibling-identifier="${this.drag_keyTarget.dataset.siblingIdentifier}"]`;
    // const siblings = document.querySelectorAll(selector);
    // siblings.forEach((el, index) => {
    //   const positionInput = el.querySelector(`input[name*="[position]"]`);
    //   if (positionInput) {
    //     console.log(`PREVIOUS POSITION => ${positionInput.value}`);
    //     positionInput.value = index + 1;
    //     console.log(`NEW POSITION => ${positionInput.value}`);
    //   }
    // });
  }

  getDomPosition() {
    // Get DOM position of dragged element among siblings using unique sibling identifier
    // NB: improve selector if class list is polluted by other external classes (i.e. active)
    const selector = `[data-sibling-identifier="${this.drag_keyTarget.dataset.siblingIdentifier}"]`;
    const siblings = document.querySelectorAll(selector);
    return Array.from(siblings).indexOf(this.drag_keyTarget);
  }
}
