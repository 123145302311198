import { createChannel, removeChannel } from "client/cable";

const BASE_URL = "/api/v1"; // TO CHANGE

export const FETCH_CHATS = "FETCH_CHATS";
export const FETCH_NEED = "FETCH_NEED";
export const REQUALIFY_NEED = "REQUALIFY_NEED";
export const REQUALIFY_NEED_ERROR = "REQUALIFY_NEED_ERROR";
export const MANAGE_NEED = "MANAGE_NEED";
export const MANAGE_NEED_ERROR = "MANAGE_NEED_ERROR";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_STEPS = "FETCH_STEPS";
export const FETCH_NOTIFS = "FETCH_NOTIFS";
export const FETCH_PROPOSALS = "FETCH_PROPOSALS";
export const FETCH_FIRMS = "FETCH_FIRMS";
export const FETCH_ANSWERS = "FETCH_ANSWERS";
export const POST_ANSWERS = "POST_ANSWERS";
export const POST_QUOTE = "POST_QUOTE";
export const POST_INVOICE = "POST_INVOICE";
export const FETCH_QUOTES = "FETCH_QUOTES";
export const MARK_AS_READ = "MARK_AS_READ";
export const MESSAGE_POSTED = "MESSAGE_POSTED";
export const FEEDBACK_POSTED = "FEEDBACK_POSTED";
export const NOTIFS_RECEIVED = "NOTIFS_RECEIVED";
export const FETCH_ATTACHMENTS = "FETCH_ATTACHMENTS";
export const DESTROY_ATTACHMENT = "DESTROY_ATTACHMENT";
export const SHARE_ATTACHMENT = "SHARE_ATTACHMENT";

export function appendMessage(message) {
  return {
    type: MESSAGE_POSTED,
    payload: message
  };
}

export function createMessage(chatId, author, content) {
  const url = `${BASE_URL}/chats/${chatId}/messages`;
  const body = { author, content };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());
  return {
    type: MESSAGE_POSTED,
    payload: promise
  };
}

export function fetchMessages(chatId) {
  const url = `${BASE_URL}/chats/${chatId}/messages`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );
  return {
    type: FETCH_MESSAGES,
    payload: promise
  };
}

export function fetchAttachments(needId, userId) {
  const url = `${BASE_URL}/needs/${needId}/attachments?user_id=${userId}`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );
  return {
    type: FETCH_ATTACHMENTS,
    payload: promise
  };
}

export function fetchSteps(needId) {
  const url = `${BASE_URL}/needs/${needId}/steps`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );
  return {
    type: FETCH_STEPS,
    payload: promise
  };
}

export function fetchNotifs() {
  const url = `${BASE_URL}/notifications`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_NOTIFS,
    payload: promise
  };
}

export function fetchChats(needId) {
  const url = `${BASE_URL}/needs/${needId}/chats`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_CHATS,
    payload: promise
  };
}

export function fetchNeed(needId) {
  const url = `${BASE_URL}/needs/${needId}`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_NEED,
    payload: promise
  };
}

export function destroyAttachment(filename) {
  const url = "/api/v1/attachments/detach";
  const body = { filename };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  });

  return {
    type: DESTROY_ATTACHMENT,
    payload: filename
  };
}

export function shareAttachment(filename) {
  const url = `/api/v1/attachments/share?filename=${filename}`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: SHARE_ATTACHMENT,
    payload: promise
  };
}

export function updateNeed(needId, description, feedback) {
  const url = `${BASE_URL}/needs/${needId}`;
  const promise = fetch(url, { credentials: "same-origin" })
    .then(r => r.json())
    .then(handleErrors)
    .then(res =>
      dispatch({
        type: REQUALIFY_NEED,
        payload: res
      })
    )
    .catch(error =>
      dispatch({
        type: REQUALIFY_NEED_ERROR,
        payload: error
      })
    );
}

export function fetchProposals(needId, anonym = false, chatId = null) {
  const url = `${BASE_URL}/needs/${needId}/proposals?anonym=${anonym}&chatId=${chatId}`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_PROPOSALS,
    payload: promise
  };
}

export function fetchFirms(needId) {
  const url = `${BASE_URL}/needs/${needId}/firms`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_FIRMS,
    payload: promise
  };
}

export function postFeedback(proposalId, content) {
  const url = `${BASE_URL}/proposals/${proposalId}`;
  const body = { content };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: FEEDBACK_POSTED,
    payload: promise
  };
}

export function postQuote(proposalId, quote) {
  const url = `${BASE_URL}/proposals/${proposalId}/quotes`;
  const body = { quote };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: POST_QUOTE,
    payload: promise
  };
}

export function postInvoice(invoice, chat_id) {
  const url = `${BASE_URL}/invoices`;
  const body = { invoice, chat_id };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: POST_INVOICE,
    payload: promise
  };
}

export function requalifyNeed(
  needId,
  description,
  feedback,
  transferAddress = null
) {
  const url = `${BASE_URL}/needs/${needId}`;
  const body = { description, feedback };
  if (transferAddress !== null) {
    body.transfer_address = transferAddress;
  }
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: REQUALIFY_NEED,
    payload: promise
  };
}

export function manageNeed(needId, brief, done_at, transfer_address) {
  const url = `${BASE_URL}/needs/${needId}`;
  const body = { brief, done_at, transfer_address };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: MANAGE_NEED,
    payload: promise
  };
}

export function fetchAnswers(needId) {
  const url = `${BASE_URL}/needs/${needId}/answers`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_ANSWERS,
    payload: promise
  };
}

export function postAnswers(needId, content, warn_providers) {
  const url = `${BASE_URL}/needs/${needId}/answers/edit_multiple`;
  const body = { content, warn_providers };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: POST_ANSWERS,
    payload: promise
  };
}

export function fetchQuotes(proposalId) {
  const url = `${BASE_URL}/proposals/${proposalId}/quotes`;
  const promise = fetch(url, { credentials: "same-origin" }).then(r =>
    r.json()
  );

  return {
    type: FETCH_QUOTES,
    payload: promise
  };
}

export function markAsRead(resourceIds) {
  const url = `${BASE_URL}/notifications/mark_as_read`;
  const body = { ids: resourceIds };
  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
  const promise = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    credentials: "same-origin",
    body: JSON.stringify(body)
  }).then(r => r.json());

  return {
    type: "MARK_AS_READ",
    payload: promise
  };
}

export function subscribeToChannel(channelName, roomId, callback) {
  createChannel(
    { channel: channelName, room: roomId },
    {
      received(data) {
        if (callback) callback(data);
      }
    }
  );
  return {
    type: `SUBSCRIBED_${channelName.toUpperCase()}`,
    payload: { channelName, roomId, callback }
  };
}

export function unsubscribeFromChannel(channelName, roomId, callback) {
  removeChannel({ channel: channelName, room: roomId });
  return {
    type: `SUBSCRIBED_${channelName.toUpperCase()}`,
    payload: { channelName, roomId, callback }
  };
}

export function receivedNotifs(data) {
  return {
    type: "NOTIFS_RECEIVED",
    payload: data
  };
}
