import React, { Component } from "react";
import InvoicesList from "../../containers/./invoices_list/invoices_list";
import "./invoices.css";

const selector = "#js-invoices__invoices-root";
const reactRootDomEl = document.getElementById(selector.substring(1));
const reactRootDomSelectors = {};

if (reactRootDomEl) {
  const showColumns = JSON.parse(reactRootDomEl.dataset.columns);

  reactRootDomSelectors[selector] = {
    component: InvoicesList,
    props: {
      showColumns: showColumns,
      invoices: JSON.parse(reactRootDomEl.dataset.invoices)
    }
  };
}

export { reactRootDomSelectors };
