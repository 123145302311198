import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import "./notif_counter.css";

class NotifCounter extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.count !== nextProps.count;
  }

  renderCounter = (count) => {
    return (count === 0 ? null : <span className={`notif-counter notif-counter--${count}`}>{count}</span>);
  }

  render() {
    return (
      this.renderCounter(this.props.count)
    );
  }
}

function mapStateToProps(state) {
  return {
    notifs: state.notifs.filter(n => n.read_at == null)
  };
}

export default connect(mapStateToProps, {})(NotifCounter);

