import React, { Component } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, ClearRefinements, connectStateResults } from 'react-instantsearch-dom';
import Hit from '../../components/hit/hit';
import "./search_bar.css";

const Content = connectStateResults(
  ({ searchState, searchResults }) =>
    !searchState.query || (searchResults && searchResults.nbHits !== 0) ? (
      <Hits hitComponent={Hit} />
    ) : (
      <ul className="ais-Hits-list">
        <li><a className="hit other" href="/riing">Je cherche un autre service</a></li>
      </ul>
    )
);

class SearchBar extends Component {

  render() {
    let firstLoad = true;

    const algoliaClient = algoliasearch(algoliaAppId, algoliaFrontendKey);

    const searchClient = {
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              processingTimeMS: 0,
            })),
          });
        }
        return algoliaClient.search(requests);
      }
    };

    const indexName = 'Service' + '_' + RAILS_ENV_JS;
    const components_classes = `search-bar__components search-${this.props.scope}`;
    const submitButton = this.props.scope == 'navbar' ? <div className="loupe"></div> : <div className="search-text"><div className="loupe"></div>Rechercher</div>;

    return (
      <div className="ais-InstantSearch search-bar">
        <InstantSearch indexName={indexName} searchClient={searchClient}>
          <div className={components_classes}>
            <SearchBox
              submit={submitButton}
              translations={{
                submitTitle: 'Rechercher.',
                resetTitle: 'Vider la recherche',
                placeholder: 'De quel service avez-vous besoin ? (petits travaux, ménages ...)',
              }}
            />
            <Content />
          </div>
        </InstantSearch>
      </div>
    );
  }
}



export default SearchBar;


