// https://evilmartians.com/chronicles/evil-front-part-3

import cable from "actioncable";

let consumer;

export const createChannel = (...args) => {
  if (!consumer) {
    consumer = cable.createConsumer();
  }
  return consumer.subscriptions.create(...args);
};

export const removeChannel = identifier => {
  if (!consumer) {
    consumer = cable.createConsumer();
  }
  const subscriptions = consumer.subscriptions.subscriptions.filter(sub => {
    if (sub.identifier === JSON.stringify(identifier)) {
      return consumer.subscriptions.remove(sub);
    }
  });
};
